<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-01-19 11:04:41
 * @LastEditors: hutian
 * @LastEditTime: 2021-02-26 14:19:41
-->
<template>
    <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" :width="1000" v-model="visible" @ok="toSubmit" okText="提交" :maskClosable="false">
        <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
          <a-form-model-item label="选择经销商" prop="customerName">
            <SelectConsumerModal :value.sync="rowData.customerName"  @select="toSelectConsumer"></SelectConsumerModal>
          </a-form-model-item>
            <a-form-model-item label="选择订单" prop="(orderId">
              <SelectDemandRequisitionModal :disabled="handle == 'check'" :value.sync="rowData.orderCode" :customerid="rowData.customerId"  @select="toSelectOrder"></SelectDemandRequisitionModal>
            </a-form-model-item>
            <a-form-model-item label="任务标题" prop="taskTitle">
                <a-input v-model="rowData.taskTitle" placeholder="请协助&&&&&处理"></a-input>
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark">
                <a-textarea v-model="rowData.description" placeholder="请输入描述说明"></a-textarea>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import SelectConsumerModal from "@/views/common/SelectConsumerModal.vue"
import SelectDemandRequisitionModal from "@/views/common/SelectDemandRequisitionModal.vue"
import { editTaskFollowUp, selectByIdTaskFollowUp, addTaskFollowUp } from '../api/TaskFollowUpApi'
export default {
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            // 表单验证
            formRule: {
                orderId: [
                    { required: true, message: '请输入订单编号', trigger: 'blur' }
                ],
                customerName: [
                    { required: true, message: '请输入经销商名称', trigger: 'blur' }
                ],
                taskTitle: [
                    { required: true, message: '请输入任务标题', trigger: 'blur' }
                ],
            }
        }
    },
    components: {
      SelectConsumerModal,
      SelectDemandRequisitionModal
    },
    methods: {
        /**
         * 获取行数据
         */
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            this.rowData = row
          if(handle == 'add') {
            this.rowData.flagDel = true
          }
        },
        /**
         * 表单提交
         */
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                const res = this.handle === 'add' ? await addTaskFollowUp(this.rowData) : await editTaskFollowUp(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        },
        toSelectConsumer(row) {
            this.$nextTick(() => {
                this.$set(this.rowData, 'customerId', row.id)
                this.$set(this.rowData, 'customerName', row.fname)
            })
        },
        
        toSelectOrder(row) {
          this.$set(this.rowData,'orderCode',row.orderCode)
          this.$set(this.rowData,'orderAmount',row.payAmount)
        },
    },
    created() {

    },

}
</script>

<style lang="less" scoped>

</style>
